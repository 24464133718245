// 业务状态
export const operationTypeEnum = {
  POSTPONE:'延期证书'
}

// 订单状态
export const orderStatusEnum = {
  TO_BE_PAID:'待付款',
  TO_BE_POSTPONED:'待延期',
  TO_BE_INVOICED:'待开票',
  COMPLETED:'已完成'
}

export const CHECK_YEAR = {
  "1": '一年',
  '2': '二年',
  '3':'三年',
  '4':'四年',
  '5':'五年',
}
  