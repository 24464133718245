<template>
  <div class="form-box">
    <h1 class="title">订单查询</h1>
    <el-form
      :model="orderForm"
      ref="orderForm"
      :rules="orderFormRules"
      class="operaHome_span el-form"
    >
      <el-form-item
        class="el-item-key"
        prop="key"
        label="选择证书"
        style="position: relative"
      >
        <el-select
          class="el_input"
          v-model="orderForm.key"
          placeholder="请选择证书"
        >
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
        <div style="float: right; position: absolute; right: -125px; top: 0">
          <el-button
            style="width: 116px; height: 34px"
            size="small"
            type="primary"
            @click.prevent="getCertificateMessage"
            :loading ='infoLoading'
          >
            获取设备信息
          </el-button>
        </div>
      </el-form-item>
      <div style="margin-left:-9px">
        <el-form-item prop="pin" label="输入PIN码">
        <el-input placeholder="请输入PIN码" class="el_input" v-model="orderForm.pin" show-password></el-input>
      </el-form-item>
      </div>
 
      <el-form-item>
        <el-button
          :disabled="!orderForm.key"
          :class="orderForm.key ? 'el-btn' : 'submit'"
          :type= "orderForm.key ? 'primary':'info' "
          :plain="!orderForm.key"
          @click="submitForm('orderForm')"
          :loading ='queryLoading'
          >查询订单</el-button
        >
      </el-form-item>
    </el-form>
    <div v-if="flag" >
      <Table :tableData="tableData"  @refresh="refresh"/>
    </div>
  </div>
</template>

<script>
import Table from "./components/table";
import { getTableValue } from "./serve";
import PKIDriver, { getProBySubject } from "../../utils/pki-driver";

export default {
  components: {
    Table,
  },
  data() {
    return {
      options: [],
      tableData: [],
      orderForm: {
        key: "",
        pin: "",
      },
      orderFormRules: {
        key: [{ required: true, message: "证书不能为空", trigger: "blur" }],
        pin: [{ required: true, message: "PIN不能为空", trigger: "blur" }],
      },
      flag: false,
      serialNumber: "",
      DeviceSN:'',
      queryLoading:false, 
      infoLoading:false,
    };
  },
  computed: {},
  watch: {},

  methods: {
    refresh(val){
      if(val){
        this.fetchTableValue();
      }

    },
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
      });
      if(!this.orderForm.pin){
        return
      }
      this.queryLoading = true;
      //如果输入 pin验证通过调接口，不存在直接调
      const sUserPin = this.orderForm.pin;
      if (sUserPin) {
        const pkiDriver = this.getPkiDriver();
        pkiDriver
          .verifyUserPin(this.DeviceSN, sUserPin)
          .then((res) => {
            this.flag = true;
            this.fetchTableValue();
            this.queryLoading = false
          })
          .catch((error) => {
            this.$message({
              message: `${error.errorMsg}有误,请检查`,
              type: "error",
            });
          });
          this.queryLoading = false
      }
    
    },
    //获取证书
    getCertificateMessage() {
      this.infoLoading=true;
      const pkiDriver = this.getPkiDriver();
      pkiDriver.getCertInfo().then((res) => {
        this.infoLoading=false
        this.serialNumber = res.SerialNumber;
        this.DeviceSN = res.DeviceSN
        const cn = getProBySubject(res.Subject, "cn");
        this.orderForm.key = cn;
        this.options = [];
        this.options.push(cn);
      }).catch((error)=>{
        this.infoLoading=false
        this.$message({
          message: error.errorMsg,
          type: "error",
        });
      });
    },
    getPkiDriver() {
      if (this.pkiDriver) {
        return this.pkiDriver;
      }
      this.pkiDriver = new PKIDriver();
      return this.pkiDriver;
    },
    async fetchTableValue() {
      const res = await getTableValue({ serialNumber: this.serialNumber });
     
      this.tableData = res.data;
      this.flag = true;
    },
  },
  created() {},
  // mounted() {
  //   const { certificate, serialNumber } = this.$route.query;
  //   this.serialNumber = serialNumber;
  //   this.orderForm.key = certificate;
  // },
};
</script>
<style scoped>



:deep(.el-input__inner){
  height:33px;
}
:deep(.el-button--primary){
  background-color: #2086fa;
  border-color: #2086fa;
}
.form-box{
  margin-top:5% ;

}
.title h1 {
  width: 78px;
  height: 19px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #313438;
  line-height: 42px; 
}
.title{
  text-align: center;
}
.el_input {
  width: 400px;
  height: 32px;
}
.el-btn {
  width: 116px;
  height: 32px;
  padding: 9px 18px;
  border-radius: 2px;
}
.el-form-item {
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit {
  width: 150px;
  height: 32px;
  background: #f2f6fa;
  padding: 9px 20px;
}
</style>
<style >
body{
  padding-right: 0px !important;
}
</style>