import api from '../../utils/api'


export function getTableValue(params) {
    return api('/ca/onlc/order/get-order-list', {
        method: 'GET',
        params: { ...params }
    })
}

export function invoiceManage(params) {
  return api('/ca/onlc/oss/manage/invoice', {
      method: 'POST',
      params: { ...params }
  })
}

export function previewInvoice(params) {
  return api(`/ca/onlc/oss/manage/preview-invoice`, {
      method: 'GET',
      params: { ...params }
  })
}

export function downInvoice(params) {
  return  api(`/ca/onlc/oss/manage/down-invoice`, {
    method: 'GET',
      params: { ...params }
  })
}

export function deleteOrder(params) {
  return  api(`/ca/onlc/order/delete-by-businessId?businessId=${params.businessId}`, {
    method: 'DELETE',
      // params: { ...params }
  })
}