<template>
  <el-dialog
    style="dialog"
    title="申请开票"
    :visible.sync="dialogFormVisible"
    width="610px"
  >
    <el-alert
      type="warning"
      style="padding-left: 10px"
      show-icon
      :title="
        form?.personal === '1'
          ? ' 电子普通发票和纸质发票具备同等法律效力,订单完成后24小时内开具。'
          : ' 我公司依法开具发票，请您按照税法规定使用发票。'
      "
    >
    </el-alert>
    <div class="el-form">
      <el-form
        :model="form"
        ref="form"
        :rules="rules"
        style="overflow: hidden; margin-top: 10px"
        :label-position="top"
      >
        <el-form-item label="选择发票类型" prop="personal">
          <div class="invoiceBox">
            <el-radio-group v-model="form.personal">
              <el-radio label="1">个人电子发票</el-radio>
              <el-radio label="2">单位电子普通发票</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <p
          class="left"
          style="
            margin: 0 0 10px 0;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          "
        >
          <span style="color: red; font-size: 8px">*</span> 填写开票信息
        </p>
        <div class="box">
          <el-form-item
          label="发票抬头"
          prop="consumerName"
     
        >
          <el-input
            placeholder="请输入"
            class="form-item_input"
            v-model="form.consumerName"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="form.personal === '2'"
          label="税号"
          prop="consumerTax"
        
        >
          <el-input
            placeholder="请输入"
            class="form-item_input"
            v-model="form.consumerTax"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="手机号"
          prop="consumerMobile"
        
        >
          <el-input
            placeholder="请输入"
            class="form-item_input"
            v-model="form.consumerMobile"
          ></el-input>
        </el-form-item>

 
        </div>
       
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submit('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { invoiceManage } from "../../../../serve";
import { Notification } from "element-ui";
export default {
  props: ["visibleDialog", "orderId"],
  data() {
    return {
      dialogFormVisible: this.visibleDialog,
      form: {
        personal: "1",
        consumerName: "",
        consumerMobile: "",
        consumerTax: "",
      },
      rules: {
        personal: [
          { required: true, message: "请选择发票类型", trigger: "change" },
        ],
        consumerName: [
          { required: true, message: "请输入发票抬头", trigger: "change" },
        ],
        consumerMobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
          ,
          {
            pattern: /^1\d{10}$/,
            message: "非法的手机号码",
            trigger: "change",
          },
        ],
        consumerTax: [
          { required: true, message: "请输入税号", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  watch: {
    visibleDialog: {
      handler(val) {
        this.dialogFormVisible = val;
        if (!val) {
          const form = this.$refs.form;
          form.resetFields();
        }
      },
      immediate: true,
    },
  },

  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.invoice();
        } else {
          return false;
        }
      });
    },
    async invoice() {
      const res = await invoiceManage({
        personal: this.form.personal == "1" ? true : false,
        orderId: this.orderId,
        consumerMobile: this.form.consumerMobile,
        consumerName: this.form.consumerName,
        consumerTax: this.form.consumerTax || undefined,
      });
      const { data } = res;
      if (data) {
        this.$emit("dialogOpen", false);
        Notification.success({
          title: "申请成功",
        });
      }
    },
  },
  created() {},
  mounted() {},
  updated() {
    if (!this.dialogFormVisible) {
      this.$emit("dialogOpen", this.dialogFormVisible);
    }
  },
};
</script>
<style scoped>
.box{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
}
.invoiceBox{
  display: block;
  margin-top:42px;
  margin-left:5px;
}
/* .clear {
  clear: both;
}
.left {
  float: left;
} */
/* >>> .el-dialog__header {
  margin-top: 5px;
  padding: 15px 0px 31px 18px;
  text-align: left;
} */
/* .el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 0;
} */
:deep(.el-alert--warning.is-light) {
  background: #fff7eb;
  border-color: #fff7eb;
  color: #ff8726;
}
:deep(.el-input__inner) {
  font-weight: 400;
}
/* >>> .el-dialog__body {
  padding: 5px 15px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
} */
/* .el-form-item {
  margin-bottom: 10px;
} */
.form-item_input {
  width: 280px;
  height: 32px;
}
</style>