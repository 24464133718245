<template>
  <div style="width: 65%; margin: auto;margin-bottom: 150px;margin-top: 50px;">
    <p class="top_p">
      共<span>{{ total }}</span
      >条订单信息
    </p> <br/>
    <el-table :data="dataSource" align="center"  height="330px">
      <el-table-column prop="createTime" label="时间"> </el-table-column>
      <el-table-column prop="certName" label="证书CN"> </el-table-column>
      <el-table-column prop="projectName" label="项目名称" > </el-table-column>
      <el-table-column prop="goodsName" label="延期年限" width="150">
        <template slot-scope="scope">
              <span>{{CHECK_YEAR[scope.row.specifications.DURATION] }}</span>
            </template>  
      </el-table-column>
      <el-table-column prop="finalPrice" label="实付金额" > </el-table-column>
      <el-table-column prop="operationType" label="业务" width="145">
        <template slot-scope="scope">
              <span>{{operationTypeEnum[scope.row.operationType] }}</span>
            </template>
      </el-table-column>
      <el-table-column prop="orderStatus" label="订单状态" width="145"> 
        <template slot-scope="scope">
              <span>{{orderStatusEnum[scope.row.orderStatus] }}</span>
            </template>
      </el-table-column>
      <el-table-column prop="invoicing" label="开票状态" width="145">
        <template slot-scope="scope">
              <span>{{scope.row.invoicing ? '开票':'未开票' }}</span>
            </template>
      </el-table-column>
      <el-table-column label="操作">
        <div class="template-span" slot-scope="scope">
          <span @click="visibleState(scope)" v-if="!scope.row.invoicing && scope.row.orderStatus == 'COMPLETED' && scope.row.finalPrice!='0.00'"> 申请开票 </span>
          <span @click="viewInvoice(scope)" v-if="scope.row.invoicing && scope.row.orderStatus == 'COMPLETED' " >查看</span>
          <span @click="downloadInvoice(scope)" v-if="scope.row.invoicing && scope.row.orderStatus == 'COMPLETED'">下载</span>
          <span v-if="scope.row.orderStatus == 'TO_BE_POSTPONED'" @click="routerOperation(scope)">去延期</span>
          <span v-if="scope.row.orderStatus == 'TO_BE_PAID'" @click="routerPay(scope)">去付款</span>
          <span v-if="scope.row.orderStatus == 'TO_BE_PAID'" @click="cancelOrder(scope)">取消订单</span>
        </div>
      </el-table-column>
    </el-table>
    <Dialog @dialogOpen="dialogOpen" :visibleDialog="visibleDialog" :orderId="orderId"></Dialog>
  </div>
</template>

<script>
import Dialog from "./components/dialog";
import { operationTypeEnum,orderStatusEnum,CHECK_YEAR } from "../../../../utils/enum";
import { downInvoice, previewInvoice,deleteOrder} from "../../serve";

export default {
  props: ["tableData"],

  data() {
    return {
      operationTypeEnum,
      orderStatusEnum,
      CHECK_YEAR,
      dataSource: this.tableData,
      total: "",
      visibleDialog: false,
      orderId:'',
    };

  },
  computed: {},
  watch: {
    tableData: {
      handler(val) {
        this.dataSource = val;
        this.total = this.dataSource.length;
      },
      immediate: true,
    },
  },

  methods: {
     cancelOrder(values){
      this.$confirm('此操作将删除订单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async() => {
       try {
        const res = await deleteOrder({businessId:values.row.id}) 
        const {data} = res
        if(data){
          this.$emit("refresh",true)
          this.$message({
            type: 'success',
            message: '取消成功!'
          });
        }
      } catch (error) { 
      }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      },
  
  
    routerOperation(value) {
      this.$router.push({
          name: "operation",
          query: {
            orderId:value.row.orderId
          },
        });
    },
    routerPay(value){
   
      this.$router.push({
          name: "pay",
          query: {
            orderId:value.row.orderId
          },
        });
    },
      visibleState(values) {
      this.orderId = values.row.orderId
      this.visibleDialog = true;
    },
    dialogOpen(val) {
      this.visibleDialog = val;
      if(!val){
        this.$emit("refresh",true)
      }
    },
   async viewInvoice(val){
     const res = await previewInvoice({
      orderId:val.row.orderId
     })
     const {data} = res
     if(data){
      window.open(data)
     }
    },
    async downloadInvoice(val){
      try {
        const res = await downInvoice({
        orderId:val.row.orderId
      })
      const {data} = res
      window.open(data)
      } catch (error) {
       
      }
    
    }
  },
  created() {},
  mounted() {},
  components: {
    Dialog,
  },
};
</script>
<style scoped>
:deep(.el-table th.el-table__cell){
  background: #FAFCFF;
  color: #313233;
  font-size:14px ;
}
:deep(.el-table::before ){
height:0px;
}
.template-span span {
  margin: 0 3px;
  cursor: pointer;
  color: #2086FA 
}
.top_p {
  margin: 0;
  margin-bottom: 20px;
  float: left;
  font-weight: 550;
  color: #313233;
  font-size: 15px;
}
.top_p span {
  color: rgb(46, 111, 186);
}
</style>